import React from "react"

const CES2025 = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        marginLeft: "-10px",
        marginTop: "-10px",
      }}
    >
      <object
        data="/documents/ces_catalogue.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Your browser does not support PDFs.
          <a href="/documents/ces_catalogue.pdf">Download the PDF</a>
        </p>
      </object>
    </div>
  )
}

export default CES2025
